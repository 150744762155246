const brandDomains = [ 
	{
		domain: 'www.yolo247.co',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.yolo247.live',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.yolo247.com',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},

	{
		domain: 'www.yolo247.site',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},

	{
		domain: 'www.yolo247.net',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},

	{
		domain: 'www.yowo247.com',
		id: 31,
		type: 'CLIENT',
		dev: 'PROD'
	},

	{
		domain: 'cashsite.gamestest.link',
		id: 31,
		type: 'CLIENT/AGENT',
		dev: 'STAGING'
	},
	{
		domain: 'cashsite2.gamestest.link',
		id: 31,
		type: 'CLIENT/AGENT',
		dev: 'STAGING'
	},
	{
		domain: 'preprod.yolo247.com',
		id: 31,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'preprod.yolo247.co',
		id: 31,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'preprod.yolo247.net',
		id: 31,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'preprod.yolo247.live',
		id: 31,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},
	{
		domain: 'www.yolo247.club',
		id: 32,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'preprod.yolo247.club',
		id: 32,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},

	{
		domain: 'www.yolo247club.co',
		id: 32,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.yolo247club.com',
		id: 32,
		type: 'CLIENT',
		dev: 'PROD'
	},


	{
		domain: 'a-cashsite.gamestest.link',
		id: 32,
		type: 'CLIENT',
		dev: 'STAGING'
	},

    //betdaily whitelabel brandid 33
	{
		domain: 'www.baaziadda.com',
		id: 33,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.baaziadda.co',
		id: 33,
		type: 'CLIENT',
		dev: 'PROD'
	},{
		domain: 'www.baaziadda.net',
		id: 33,
		type: 'CLIENT',
		dev: 'PROD'
	},


	{
		domain: 'betdaily.gamestest.link',
		id: 33,
		type: 'CLIENT',
		dev: 'STAGING'
	},
	{
		domain: 'preprod.baaziadda.com',
		id: 33,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},



	///betdaily whitelabel brandid 31 
	{
		domain: 'www.betdaily.club',
		id: 34,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'club-betdaily.gamestest.link',
		id: 34,
		type: 'CLIENT',
		dev: 'STAGING' 
	},
	{
		domain: 'preprod.betdaily.club',
		id: 34,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},


	///////// fomo7 whitelabel brandid 39 


	{
		domain: 'www.fomo7.com',
		id: 39,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.fomo7.net',
		id: 39,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.fomo7.co',
		id: 39,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'www.foma7.com',
		id: 39,
		type: 'CLIENT',
		dev: 'PROD'
	},
	{
		domain: 'fomo7.gamestest.link',
		id: 39,
		type: 'CLIENT',
		dev: 'STAGING' 
	},
	{
		domain: 'preprod.fomo7.com',
		id: 39,
		type: 'CLIENT',
		dev: 'PRE-PROD'
	},

		///////// fomo7 Club whitelabel brandid 40


		{
			domain: 'www.fomo7.xyz',
			id: 40,
			type: 'CLIENT',
			dev: 'PROD'
		},
		{
			domain: 'club-fomo7.gamestest.link',
			id: 40,
			type: 'CLIENT',
			dev: 'STAGING' 
		},
		{
			domain: 'preprod.fomo7.xyz',
			id: 40,
			type: 'CLIENT',
			dev: 'PRE-PROD'
		},
	



	//////local host//////
	
	{
		domain: 'localhost:3000',
		id: 39,
		type: 'CLIENT/AGENT',
		dev: 'DEV'
	},
];

 
export default brandDomains;