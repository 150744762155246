import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { Helmet } from "react-helmet";
import { withTheme } from 'styled-components';
import { withAppContext } from 'src/store/initAppContext';
import BTIseo39 from './BTIseo39';

const structuredData = (domain) => {

  const faqData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": `Which are the popular types of Sportsbook betting on ${domain} ?`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting."
      }
    }, {
      "@type": "Question",
      "name": `Does ${domain} have domestic cricket matches too?`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `Be it international or domestic cricket matches, ${domain} hosts all kinds of cricket matches.`
      }
    }]
  };

  // Combine both data objects
  const combinedData = [faqData];

  return JSON.stringify(combinedData);
};

const BTIseo = (props) => {

  const { app: { brandId } } = props;

  let domainURL = 'https://www.yolo247.co';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.baaziadda.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
} else if (brandId === 40) {
  domainURL = 'https://www.fomo7.xyz';
}

const domainName = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7':  'Betdaily' ;



  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(domainURL)
          }
        </script>
      </Helmet>
      <AccordionWrapper>
        <Accordion>
          <input type="checkbox" id="section1" className="accordion__input" />
          <label htmlFor="section1" className="accordion__label"><h1>{(brandId === 39 || brandId === 40) ? "Sportsbook Betting On FOMO7" : "SPORTSBOOK"}</h1></label>
          {(brandId === 39 || brandId === 40) ? <BTIseo39/> : 
          <div className="accordion__content">
            <h1>Sportsbook Betting | Best Online Sportsbook In India</h1>
            <p>Sportsbook betting is a popular pastime among Indian sports fans. And for that, they usually visit several online sportsbooks.</p>
            <p>{domainName} is one of the online betting platforms where fans like you can enjoy the thrill of betting. {domainName} provides the best online Sportsbook in India.</p>
            <p>In recent years, the number of fans in India who do sports betting has grown manifold. Some of them want the thrill of betting on cricket. They enjoy their time at {domainName}, which not only provides <a href={`${domainURL}/betting-exchange/online-cricket-betting`}>cricket betting</a> but other sports as well.</p>
            <p>{domainName} provides you with the best online sportsbook betting experience. Get a grasp of the history of sports betting.</p>
            <h2>Overview of Sportsbook Betting</h2>
            <p>Sports betting in India remains prehistoric. Archaeologists have found how ancient societies in the Indus River valley used to play dice.</p>
            <p>Even in medieval times, betting was prevalent, as there was evidence of betting on cockfighting. It was only when the Britishers arrived many sports came to India. It included horse racing which became a medium of sports betting. They also brought cricket, which would eventually become a source of betting among the Indian middle class.</p>
            <p>Meanwhile, more than 100 years later, economic liberalization happened, and the middle class benefited the most. These same people started betting on their favorite pastime–Cricket.</p>
            <p>Later from 2016 onwards, the digital revolution ensured that people could bet from the comfort of their homes as they were just a tap away from placing a wager. Sportsbook betting makes online gambling more accessible, safer, and smarter. It is mainly because of its transparency and options.</p>
            <h2>Cricket Sportsbook Betting on {domainName}</h2>
            <p>{domainName} provides you with the service of a cricket sportsbook where you can place bets on your favorite cricket matches. For all tournaments, be it the Indian Premier League (IPL), Pakistan Super League, or any international match, you can bet on any of them.</p>
            <p>Moreover, you can also pick any domestic match in India or any other country. Placing bets is also very easy. Just go to the {domainName} homepage and select the sportsbook section. Once you land on the page, you can choose any one of the tournaments that are on display.</p>
            <p>Here are some of the prominent tournaments that are available on {domainName} Sportsbook.</p>
            <ul>
              <li>Indian Premier League (IPL)</li>
              <li>Big Bash League (BBL)</li>
              <li>Caribbean Premier League (CPL)</li>
              <li>Pakistan Super League (PSL)</li>
              <li>International cricket</li>
              <li>Domestic matches</li>
            </ul>
            <h2>Soccer Sportsbook | Online Football Sportsbook Betting</h2>
            <p>Like Cricket, {domainName} has a separate section for Football or Soccer. If you are interested in football betting, you can bet on the online football sportsbook. English Premier League, Spanish La Liga, or Dutch League, you name it. We have all the available football leagues to bet on.</p>
            <p>Once you land on the homepage, you can select the Sportsbook and pick the soccer section from the left-hand side of the screen. Here are some soccer leagues you can place a wager on in sportsbook betting.</p>
            <ul>
              <li><a href={`${domainURL}/virtualSports/vfel`}>English Premier League</a></li>
              <li>Spanish La Liga</li>
              <li>Bundesliga</li>
              <li>Serie A</li>
              <li>World Cup</li>
              <li>Euro Cup</li>
              <li>International friendlies</li>
              <li>Indian Super League</li>
              <li>England-Championship</li>
              <li>England-League One</li>
            </ul>
            <p>Besides, you can bet on other domestic leagues of South Korea, Brazil, Japan, Argentina, the Netherlands, etc.</p>
            <h2>Basketball Sportsbook</h2>
            <p>Now, we move to the third most popular sport in the world, yes you guessed it right: Basketball. The basketball sportsbook in {domainName} has all the leagues that you want.</p>
            <p>You can select any match from this league and then place the bet, which is shown on the right side. The minimum price of your bet will be shown on the right-hand side of the screen. It will also show the maximum range to which your bet can reach.</p>
            <p>We have leagues from all over the world. USA, Vietnam, Australia, Bolivia, etc.</p>
            <ul>
              <li>NBA</li>
              <li>Bolivia-Libobasquet</li>
              <li>Indonesia-IBL</li>
              <li>Vietnam-VBA</li>
              <li>Uruguay-Metro League</li>
            </ul>
            <h2>Tennis Betting</h2>
            <p>In this section, you can satiate all your Tennis betting needs. Just select the Sportsbook section and select Tennis from the left-hand side dropdown. Choose a Tennis match you wish to bet on.</p>
            <p>We have a vast number of matches to bet on. Some of them are given below. However, most of the leagues are categorized under the nation category. Some of the big tournaments that are available are:</p>
            <ul>
              <li>Australian Open</li>
              <li>French Open</li>
              <li>Wimbledon</li>
              <li>US Open</li>
            </ul>
            <p>Besides this, we also have leagues from lesser-known nations, like Mexico, Argentina, and Australia.</p>
            <ul>
              <li>Argentina-ITF M25 Rosario</li>
              <li>México-UTR Pro Manzanillo</li>
              <li>ITF W15 Tianjin</li>
            </ul>
            <h3>Other Sports Available On {domainName} Sportsbook Betting</h3>
            <p>As mentioned above, the {domainName} Sportsbook provides multiple options for sports bets. We have already mentioned prominent sports available for betting, like Cricket, Football, and Tennis. But there are much more <a href={`${domainURL}/betting-exchange`}>sports betting</a> options available:</p>
            <ul>
              <li>Kabaddi</li>
              <li>Badminton</li>
              <li>Baseball</li>
              <li>Volleyball</li>
              <li>Boxing</li>
              <li>Cycling</li>
              <li>Futsal</li>
              <li>Football</li>
              <li>Ice Hockey</li>
              <li>Handball</li>
              {(brandId === 31 || brandId === 32) && (
                <>
                  <li>Rugby League</li>
                  <li>Golf</li>
                  <li>Snooker & Pool</li>
                  <li>Motor Racing</li>
                  <li>Table Tennis</li>
                  <li>Water Polo</li>
                  <li>Rugby Union</li>
                  <li>MMA</li>
                </>
              )}
            </ul>
            <p>The long list of sporting events ensures a rich and rewarding experience awaits sports enthusiasts on {domainName}.</p>
            <h2>Features of {domainName} Sportsbook</h2>
            <p>Now, you know the premier reasons for using the {domainName} sportsbook, but you want to know the features of one of the best sportsbooks in India. There are primarily three reasons.</p>
            <h3>Fast:</h3>
            <p>{domainName} sportsbook betting is very fast and hence one of the favorite modes for sports betting in India. Upon landing on the homepage, you can see the odds for the match. Place them and wait for the results. We ensure there would be no hang time at all.</p>
            <h3>Multiple Bets:</h3>
            <p>Also, you can place multiple bets in Sportsbook betting. For example, the IPL is happening, and you have multiple matches on the same day. Having the option of putting various bets means you can place a wager on IPL games any number of times.</p>
            <p>These key features offer a thrilling and engaging experience in {domainName} Sportsbook.</p>
            <h2>Why Choose {domainName} Sportsbook For Sports Betting In India?</h2>
            <p>There are several reasons why you should choose <a href={`${domainURL}`}>{domainName}</a>.</p>
            <h3>User-friendly and Easy Navigation:</h3>
            <p>New users love the easy navigation that is on offer. They would land on the page with a strong urge to bet on their favorite sport. Upon landing, our effective navigation directs them to the Sportsbook, where they can see the number of sports available for betting. Effective navigation helps in saving your time and helps retain the new user.</p>
            <h3>Well Defined and Illustrated:</h3>
            <p>Another aspect of user-friendliness is that our website is very well illustrated. Once you enter the homepage, you can quickly locate Sportsbook. Upon clicking this option, you can also see the bets clearly defined with the match on which you are placing a wager being highlighted.</p>
            <h3>Well-Defined Odds in Sportsbook Betting:</h3>
            <p>Also, once you place the bet, you can see the minimum amount of money you need to wager. You can also see how much money you will get if you win the bet.</p>
            <h3>24x7 Customer Service:</h3>
            <p>We also have dedicated customer service, which operates 24x7. In case you have any queries regarding sportsbook betting, you are always welcome to communicate with us over phone, e-mail, and live chat.</p>
            <h3>Multiple Modes of Payment:</h3>
            <p>Another reason for picking the {domainName} sportsbook betting is to take advantage of the multiple payment modes available. It can be e-wallets or UPI, or Debit/Credit cards.</p>
            <p>Though we have listed only a few, more compelling reasons exist to make {domainName} the best platform to place your bets.</p>
            <h2>FAQs about Sportsbook Betting</h2>
            <ol>
              <li><strong>Which are the popular types of Sportsbook betting?</strong></li>
              <p>Cricket, football, basketball, tennis, and kabaddi are popular types of Sportsbook betting.</p>
              <li><strong>Does {domainName} have domestic cricket matches too?</strong></li>
              <p>Be it international or domestic cricket matches, {domainName} hosts all kinds of cricket matches.</p>
            </ol>
          </div>}
        </Accordion>
      </AccordionWrapper>
    </>
  )
}

export default withTheme(withAppContext(BTIseo));