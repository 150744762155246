import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatrixFlyAndPlayPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 31 || brandId == 32) ? "Yolo247" : (brandId == 39 || brandId == 40) ? "FOMO7" : 'BaaziAdda';

    return (
        <>
            <InformationWrapper>
                {(brandId == 39 || brandId == 40) ? <TitleText>
                    <h6>Get ready for the "<b>Take Off and Win Big!</b>" tournament, a thrilling three-month competition starting on <b>November</b> <span className='pinkBold'>1st</span>, <b>2024</b>, and running through <b>November</b> <span className='pinkBold'>30th</span>, <b>2024</b>.With a total prize pool of <span className='pinkBold'>Rs. 77 lacs</span>, the excitement never stops! Daily prize payouts will be available throughout the tournament, <b>from November</b> <span className='pinkBold'>1st</span> <b>to November</b> <span className='pinkBold'>30th</span>, <b>2024</b>.</h6>
                    <h5>Best of all, there’s <b>No Minimum Bet Required To Join The Fun</b>.</h5>
                </TitleText> : <TitleText>
                    <h6>Get ready for the "<span className={(brandId == 31 || brandId == 32) && 'yoloText'}>Take Off and Win Big!</span>" tournament, a thrilling three-month competition starting on <span className={(brandId == 31 || brandId == 32) && 'yoloText'}> November 1st, 2024</span>, and running through <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>November 30th, 2024</span>. With a total prize pool of <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>{brandId === 33 ? 'Rs. 71 lacs': 'Rs. 74 lacs'}</span>, the excitement never stops! Daily prize payouts will be available throughout the tournament, from <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>November 1st to November 30th, 2024</span>.</h6>
                    <h5>Best of all, there’s <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>No Minimum Bet Required</span> To Join The Fun.</h5>
                </TitleText>}
                <ROWrapper>
                    <ROLeftWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlOne}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </ROLeftWrapper>
                    <RoContent>
                        <h1>How to Participate:</h1>
                        <h3>To compete in the daily tournaments, simply follow these two steps:</h3>
                        <TextWrapper>
                            <span>Stage&nbsp;1:</span>
                            <p>Place at least one bet using your NFT aircraft within a <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>24-hour</span> period.</p>
                        </TextWrapper>
                        <TextWrapper>
                            <span>Stage&nbsp;2:</span>
                            <p>Claim your reward if you’ve earned one. When your aircraft wins the daily tournament, your reward will be based on its overall game experience. The more experience your aircraft has, the bigger the reward!</p>
                        </TextWrapper>
                    </RoContent>
                </ROWrapper>
                <UnderLine></UnderLine>
                <DetailsWrapper>
                    <HeadingText>
                        <h1>Tournament Details</h1>
                    </HeadingText>
                    <DetailsBodyWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgOne}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Rewards are distributed every <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>24 hours at 19:00/12:30 am India time UTC</span>.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgTwo}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>Each bet earns points equal to the bet amount, and these points are displayed in the real-time leaderboard within the <b>Aviatrix game</b> interface.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgThree}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>If multiple players have the same number of points on the leaderboard, the player who reached that point total first will rank higher.</p>
                            </EachBody>
                        </EachWrapper>
                        <EachWrapper>
                            <Divider></Divider>
                            <EachBody>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgFour}`}
                                    alt="jetxPromoStepThree"
                                    effect="none"
                                    placeholder={<PlaceholderImage />}
                                />
                                <p>You can use only one game account for this promotion, but feel free to use as many NFT aircraft as you like.</p>
                            </EachBody>
                        </EachWrapper>
                    </DetailsBodyWrapper>
                </DetailsWrapper>
                <BodyWrapper>
                    <LeftWrapper>
                        <RewardsWrapper>
                            <h1>Winning and Rewards:</h1>
                            <ul>
                                <li><p>Winners and cash rewards are determined daily at <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>19:00 UTC/ 12:30am IST</span>, with the process split into two stages.</p></li>
                                <li><p>The higher your game turnover, the better your chances of winning.</p></li>
                                <li><p>During each competition period, every aircraft’s minimum turnover (bet) equals one chance (ticket). The chances for other participating aircraft are proportional to this minimum turnover.</p></li>
                                <li><p>Once you advance to the second stage, you’ll receive your winnings based on your overall gaming experience (total bets). The more experience your aircraft has, the bigger the cash reward.</p></li>
                                <li><p>To claim your cash prize, simply click the "<span className={(brandId == 31 || brandId == 32) && 'yoloText'}>Claim Prize</span>" button, and your winnings will be credited to your account. These cash prizes have no wagering requirements and can be used for gameplay or withdrawn.</p></li>
                            </ul>
                        </RewardsWrapper>
                    </LeftWrapper>
                    <RightWrapper>
                        <LazyLoadImage
                            src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlTwo}`}
                            alt="jetxPromoStepThree"
                            effect="none"
                            placeholder={<PlaceholderImage />}
                        />
                    </RightWrapper>
                </BodyWrapper>
                <AviatrixTermsAndCondition>
                    <h4>Terms & Conditions</h4>
                    <ul>
                        <li>This promotion is exclusively for the game AVIATRIX from <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>November 1st to November 30th , 2024</span>.</li>
                        <li>The total prize pool is <span className={(brandId == 31 || brandId == 32) && 'yoloText'}>{(brandId === 31 || brandId === 32) ? 'Rs. 74 lacs' : (brandId === 39 || brandId === 40) ? 'Rs. 77 lacs' : 'Rs. 71 lacs'}</span></li>
                        <li>Multiple accounts will not be considered for promotion eligibility. Duplicate checking will be conducted, and {domain} & AVIATRIX  reserves the right to disqualify players if collusion or foul play is detected.</li>
                        <li>{domain} & AVIATRIX  reserves the right to amend, suspend, or cancel the promotion at any time without prior notice. General Terms & Conditions apply.</li>
                    </ul>
                </AviatrixTermsAndCondition>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(AviatrixFlyAndPlayPromo));

const InformationWrapper = styled.div`
    width: 98%;
    height: auto;
    padding: 3rem 3rem 8rem 3rem;
    border-radius: 1px;
    background: rgba(111, 111, 111, 0.5);
    margin: 0em 1.2em 0 1.2em;
    border: 1px solid #E5B358;
    border-radius: 0 0 20px 20px;
    border-top-color: transparent;
    backdrop-filter: brightness(0.5);    
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .yoloText {
        color: #F3B136;
        font-weight: 700;
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h6 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
        text-align: center;
        width: 90%;
    }

    h5 {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 400;
        line-height: 28px; /* 186.667% */
    }

    .pinkBold {
        color: #FF9FFF;
        font-weight: 700;
    }

    .yoloText {
        color: #F3B136;
        font-weight: 700;
    }

    span {
        color: #FFF;
        font-weight: 700;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    padding-top: 7rem;
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 45%;
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    align-self: flex-end;

    img {
        width: 35rem;
        margin-bottom: -0.4rem;
    }
`;

const RewardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    h1 {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        margin-bottom: 0;
    }

    ul {
        margin: 0;
        padding: 0 0 2rem 1rem;
    }

    ul li {
        color: #FFF;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-weight: 700;
        padding-top: 1.5px;
    }

    p {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
        text-transform: capitalize;
    }
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    margin: 0 ;
    gap: 1.5rem;
`;

const DetailsBodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
`;

const EachWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 18rem;
    border-radius: 20px;
    border: ${theme('palette.AviatrixFlyandPlayTermsBorder')};
    background: ${theme('palette.AviatrixFlyandPlayItemsBackground')};
`;

const EachBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1.5rem 1.5rem 1.5rem;
    gap: 1rem;
    position: relative;

    img {
        position: absolute;
        width: 15rem;
        top: -10%;
        right: 5%;
        text-align: center;
    }

    p {
        margin: 0;
        padding-top: 9rem;
        color: #FFF;
        text-align: center;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }
`;

const Divider = styled.div`
    height: 4px;
    width: 60%;
    border-radius: 76px;
    background: ${theme('palette.AviatrixFlyandPlayDividerBg')};
    box-shadow: ${theme('palette.AviatrixFlyandPlayDividerBoxShadow')};
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        margin: 0;
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    gap: 10rem;
    width: 100%;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    img {
        width: 35rem;
        text-align: center;
        margin-bottom: -0.3rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding-right: 2rem;
    border-radius: 20px;
    background: ${theme('palette.AviatrixStagesbackgrond')};
    border: ${theme('palette.AviatrixStagesborder')};
    padding: 3rem;
    align-self: center;
    position: relative;


    h1 {
        margin: 0;
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
    }

    h3 {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgFive")});
        width: 17rem;
        height: 16rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5rem;
        right: -6rem;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 100%;
    background: radial-gradient(#FFF 0%, rgba(153, 153, 153, 0.00) 70%);
`;

const AviatrixTermsAndCondition = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 5%;
    padding: 5rem;
    position: relative;
    border-radius: 20px;
    border: ${theme('palette.AviatrixFlyandPlayTermsBorder')};
    background: ${theme('palette.AviatrixFlyandPlayTermsBackground')};


    & li {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 1rem 0;
    }

    & h4 {
        color: ${theme('palette.AviatrixFlyandPlayTextColor')};
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSix")});
        width: 10rem;
        height: 8rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 5rem;
        right: 6rem;
    }

    ::after {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSeveen")});
        width: 6rem;
        height: 5rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 1rem;
        left: 2rem;
    }
`;